.page-title {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  background-image: url("./Components/Images/cover_image.png");
}

.page-title > p {
  margin: 10px 0px;
  padding: 0px 10px;
  font-weight: 900;
  color: white;
  border: 1px solid white;
}

.steps-heading {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0rem 3rem;
  text-align: center;
}

/* ==============Button CSS============ */
.home-page-decoration {
  margin-bottom: 2rem;
}

.button-82-pushable {
  text-decoration: none;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button-82-edge {
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.button-82-front {
  text-align: center;
  text-decoration: none;
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background: rgb(164, 12, 12);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  text-decoration: none;
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}

/* =================================Form ANTD================================= */
.Item {
  /* border: 2px solid black; */
  font-size: 2rem;
}
.Input {
  padding: 5px 10px;
  font-size: 1rem;
}
.ant-select-selection-search-input {
  padding: 10px;
  height: auto;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  padding: 10px;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-form-vertical
  .ant-form-item-label
  > label {
  font-size: 1rem;
  height: auto;
}
.TextArea {
  padding: 5px 10px;
  font-size: 1rem;
}
:where(.css-dev-only-do-not-override-178su4t).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  height: auto;
  padding: 5px 10px;
}
/* :where(.css-dev-only-do-not-override-ph9edi).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: rgb(164 12 12);
} */
:where(.css-dev-only-do-not-override-ph9edi).ant-select .ant-select-arrow {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  pointer-events: none;
  margin-right: 10px;
}
:where(.css-dev-only-do-not-override-nkd4u0).ant-form-vertical
  .ant-form-item-label,
:where(.css-dev-only-do-not-override-nkd4u0).ant-col-24.ant-form-item-label,
:where(.css-dev-only-do-not-override-nkd4u0).ant-col-xl-24.ant-form-item-label {
  text-align: start;
}
/* :where(.css-dev-only-do-not-override-ph9edi).ant-select:focus,
:where(.css-dev-only-do-not-override-ph9edi).ant-select-focused {
  border-color: rgb(164 12 12);
  border-inline-end-width: 1px;
  outline: 0;
} */
/* :where(.css-dev-only-do-not-override-ph9edi).ant-input:focus,
:where(.css-dev-only-do-not-override-ph9edi).ant-input-focused {
  border-color: rgb(164 12 12);
  box-shadow: 0 0 0 2px rgba(164, 12, 12, 0.1);
  border-inline-end-width: 1px;
  outline: 0;
} */
/* :where(.css-dev-only-do-not-override-ph9edi).ant-input:hover {
  border-color: rgb(164 12 12);
  border-inline-end-width: 1px;
} */
/* =================================Media Query================================= */
@media screen and (max-width: 600px) {
  .page-title {
    font-size: 2rem;
  }
  .steps-heading {
    font-size: 1.8rem;
  }
}
