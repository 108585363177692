.getCashOffer {
  width: 80vw;
  padding: 20px 0px;
  margin: 0px auto;
}

.form-inside-information-label {
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(164, 12, 12);
  margin-bottom: 1rem;
}

.get-a-cash-offer-button-container {
  display: flex;
  justify-content: flex-end;
}

.get-a-cash-offer-button {
  border: 2px solid rgb(164, 12, 12);
  background-color: rgba(164, 12, 12, 0.153);
  padding: 5px;
  font-size: 1rem;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
/* =============Form styling==================*/
#approxSQFootafe {
  width: 96.5%;
  padding: 10px;
  font-size: 1rem;
  margin: 0px 10px 0px;
}
