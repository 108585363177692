.about-body {
  width: 80vw;
  margin: 0px auto;
}

.about-body > p {
  font-size: 1.3rem;
}

.about-compney-name {
  color: rgb(164, 12, 12);
}

.image-text {
  display: flex;
}

.about-iamge {
  display: flex;
}

.about-iamge > img {
  width: 400px;
  aspect-ratio: 1.3/1.05;
  margin: 0px 30px 30px 0px;
}

.about-image-right > p {
  font-size: 1.3rem;
}

/* ==============MediaQuery============== */
@media screen and (max-width: 1024px) {
  .image-text {
    display: flex;
    flex-direction: column;
  }

  .about-iamge {
    display: flex;
    justify-content: center;
  }

  .about-iamge > img {
    width: 350px;
    aspect-ratio: 1.3/1.05;
    margin: 0px auto;
  }

  .about-image-right > p {
    font-size: 1.3rem;
  }

  .about-body > p {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 600px) {
  .about-body {
    width: 85vw;
  }
  .about-iamge > img {
    width: 318px;
    aspect-ratio: 1.3/1.05;
  }
}
