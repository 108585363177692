.questions-page {
  width: 80vw;
  margin: 0px auto;
}
.faq-questions {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.faq-questoin-left-all {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-decoration: none;
}
.faq-questoin-left-all > a {
  text-decoration: none;
}
.questions-answer {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.full-question {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.faq-que {
  color: rgb(164, 12, 12);
  font-size: 1.2rem;
  display: block;
}
.faq-right-image {
  width: 15rem;
  margin: 0px 1rem;
}
.full-que-icon {
  color: rgb(164, 12, 12);
  font-size: 1.5rem;
}
.full-que-question-answer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.full-que-question {
  font-size: 900;
  color: rgb(170, 13, 13);
  font-size: 1.5rem;
}
.full-que-ans {
  font-size: 1.2rem;
}

/* ==============MediaQuery============== */
@media screen and (max-width: 1024px) {
  .faq-que {
    font-size: 1.1rem;
  }
  .faq-questions {
    display: flex;
    flex-direction: column-reverse;
  }
  .faq-right-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
    display: block;
  }
  .full-que-question {
    font-size: 1.3rem;
  }
  .full-que-ans {
    font-size: 1.05rem;
  }
}
@media screen and (max-width: 600px) {
  .faq-que {
    font-size: 1rem;
  }

  .full-que-question {
    font-size: 1.2rem;
  }
  .full-que-ans {
    font-size: 1rem;
  }
}
