.contact-page {
  width: 80vw;
  margin: 0px auto;
}

.contact-info {
  display: flex;
  flex-direction: row;
}

.contact-info-left {
  width: 43vw;
}

.contact-info-left > p {
  font-size: 2rem;
  color: rgb(164, 12, 12);
  font-weight: 600;
}
.contact-info-left > Form {
  width: 25vw;
}

.contact-button-container {
  display: flex;
  justify-content: flex-end;
}
.contact-button {
  border: 2px solid rgb(164, 12, 12);
  background-color: rgba(164, 12, 12, 0.153);
  padding: 5px;
  font-size: 1rem;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-info-right {
  width: 43vw;
}
.contact-info-right-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-info-icon {
  margin-right: 1rem;
}
.contact-info-right > p {
  font-size: 2rem;
  margin-bottom: 1.1rem;
  color: rgb(164, 12, 12);
  font-weight: 600;
}
.contact-info-type {
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
}

.contact-logo {
  height: 7rem;
  margin-top: 2rem;
}

/* ==============MediaQuery============== */
@media screen and (max-width: 1024px) {
  .contact-info {
    display: flex;
    flex-direction: column;
  }
  .contact-info-left {
    width: 90vw;
    margin-bottom: 3rem;
  }
  .contact-info-left > Form {
    width: 89%;
  }
  .contact-info-right {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-info-type {
    display: flex;
    flex-direction: row;
    font-size: 1.3rem;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
}
