.form_name_home_repair {
  font-size: 1.3rem;
  color: rgb(154, 12, 12);
  font-weight: 600;
}

.repair-each-page-container {
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.touch-repair-container {
  margin-top: 1rem;
}

.get-a-cash-offer-button-container {
  margin-top: 1.5rem;
}
