/* =========LOGO========= */
.logo {
  height: 5.5rem;
  cursor: pointer;
  margin-left: 3rem;
}

/* ========Active Link========= */
.active {
  /* font-weight: 600; */
  color: rgb(164, 12, 12);
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  color: rgb(255, 0, 0);
}

.hidden-indicator {
  visibility: hidden;
}

/* ====================== */

nav {
  height: 15vh;
  background: whitesmoke;
}

.seen {
  display: flex;
  flex-direction: column;
}

.hamburger {
  display: flex;
  flex-direction: row;
  height: 15vh;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  font-size: 1.2rem;
  gap: 1rem;
  padding-right: 3rem;
}

.navLink {
  color: black;
  text-decoration: none;
}

.title {
  color: white;
  font-size: 2rem;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
}

.navbar_content {
  display: flex;
  flex-direction: row;
  position: relative;
}

.hoverClr {
  font-weight: 500;
}

.hoverClr:hover {
  color: red;
  font-weight: 800;
}

/* ====================Main==================== */

.child {
  z-index: -1;
}

/* ===============Footer=============== */

.footer {
  margin-top: 10vmax;
  padding: 0rem 0px 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  position: relative;
  color: whitesmoke;
}

.footer-contact {
  position: relative;
  display: flex;
  width: 90vw;
  justify-content: space-between;
  /* background-color: tomato; */
  background-color: rgb(164, 12, 12);
  padding: 2vmax 2vmax;
  color: white;
  margin: 0% auto;
  bottom: 3rem;
}

.left-text {
  font-size: 2.5rem;
  font-weight: 600;
  width: 60vw;
}

.rifgt-call-info {
  font-size: 2rem;
}

.right-call-number {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
}

.footer-menu {
  display: flex;
  gap: 10px;
  color: whitesmoke;
}

.navLink-footer {
  text-decoration: none;
}

.navLink-footer>div {
  color: whitesmoke;
  opacity: 0.7;
  font-weight: 300;
}

/* -----------------------Media Queries(MD)----------------------- */
@media screen and (max-width: 1024px) {
  .logo {
    margin-top: 2px;
    height: 3rem;
    cursor: pointer;
    margin-left: 1rem;
  }

  .hoverClr {
    display: flex;
    flex-direction: row-reverse;
  }

  .active {
    font-weight: 600;
    color: rgb(164, 12, 12);
  }

  nav {
    position: relative;
    height: 13vh;
  }

  .lines {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
  }

  .title {
    transform: translateY(-30%);
  }

  .line {
    width: 30px;
    height: 3px;
    background: black;
    margin: 5px;
  }

  .title {
    top: 32px;
  }

  .reTitle {
    font-size: 2.2rem;
    transition: all 0.5s ease-in-out;
  }

  .hamburger {
    position: relative;
    background: whitesmoke;
    height: 650px;
    width: 100vw;
    flex-direction: column;
    clip-path: circle(100px at 95% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 1s ease-out;
    font-size: 1.7rem;
    justify-content: center;
    gap: 1%;
    padding-right: 0rem;
    z-index: 999;
  }

  .open {
    clip-path: circle(1800px at 90% -10%);
    -webkit-clip-path: circle(1800px at 90% -10%);
    z-index: 1000;
    position: fixed;
  }

  .navLink {
    display: flex;
    flex-direction: row-reverse;
  }

  .indicator {
    rotate: 90deg;
  }

  .left-text {
    font-size: 1.8rem;
    width: 50vw;
  }

  .rifgt-call-info {
    font-size: 1.5rem;
  }

  .right-call-number {
    font-size: 2rem;
  }
}

/* -----------------------Media Queries(SM)----------------------- */
@media screen and (max-width: 600px) {
  .footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .footer-contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left-text {
    width: 60vw;
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
  }

  .left-text>p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .right-text {
    margin-top: 1rem;
  }

  .rifgt-call-info {
    font-size: 1rem;
  }

  .right-call-number {
    font-size: 1.8rem;
  }
}